body{
    margin: 0px;
    background: #202432;
}

a, p, h1, h2, h3, h4, h5{
    color: #eeeeee;
    text-shadow: -1px -1px 0 #1a1d29, 1px -1px 0 #1a1d29, -1px 1px 0 #1a1d29, 1px 1px 0 #1a1d29;

    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}